import React from "react"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Hero from "../../components/Hero"

const PrivacyPolicyPage = () => {
  return <Layout>
    <Seo title="Privacy Policy" />
    <Hero title="Privacy Policy" />

    <section className="grid grid-cols-6 py-6">
      <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0">

        <h3 className="mb-2">Definizioni:</h3>
        <ul className="list-disc list-outside pl-9 mb-2">
          <li>
            per <i>Titolare del Trattamento</i> si intende la persona fisica o giuridica, l’autorità pubblica, il
            servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità, i mezzi del
            trattamento di <i>Dati Personali</i> e gli strumenti adottati, tra cui le misure di sicurezza relative al
            funzionamento e alla fruizione del sito internet;
          </li>
          <li>
            per <i>Regolamento</i> si intende il Regolamento (UE) n. 2016/679;
          </li>
          <li>
            per <i>Utente</i> si intende il soggetto che naviga nel sito del Dott. Francesco Latini e, salvo ove
            diversamente specificato, coincide con l’<i>Interessato</i>;
          </li>
          <li>
            per <i>Interessato</i> si intende la persona fisica cui si riferiscono i <i>Dati Personali</i>;
          </li>
          <li>
            <i>Dati Personali (o Dati)</i> si intendono le informazioni che, direttamente o indirettamente, anche in
            collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda
            identificata o identificabile una persona fisica;
          </li>
          <li>
            per <i>Normativa Applicabile</i> si intende il <i>Regolamento</i> e il Codice Privacy (Decreto Legge n.
            196/2003, così come modificato dal Decreto Legge n. 101/2018).
          </li>
        </ul>
        <p className="mb-2">Ai sensi del <i>Regolamento</i>, la presente informativa illustra le modalità e le finalità di trattamento dei <i>Dati Personali</i> degli <i>Utenti</i> che consultano il sito del Dott. Francesco Latini, psicologo psicoterapeuta iscritto all’Albo A dell’Ordine degli Psicologi della Lombardia.</p>

        <h3 className="mt-4">1. Titolare del trattamento</h3>
        <p className="mb-2">Il <i>Titolare del Trattamento</i> è il Dott. Francesco Latini, Via Luigi Porro Lambertenghi 18, Milano - 20159, Italia. Indirizzo e-mail: <i>francesco.latini.psy@gmail.com</i>.</p>
        <p className="mb-2">Il sito è raggiungibile all’indirizzo <i>www.francescolatinipsicologo.it</i> e a quello <i>francescolatinipsicologo.it</i>.</p>
        <p className="mb-2">Ove nella presente informativa si parla di <i>Sito</i> si fa sempre riferimento agli indirizzi sopracitati. La presente informativa non concerne altri siti esterni ai domini sopramenzionati.</p>

        <h3 className="mt-4">2. Finalità di trattamento</h3>
        <p className="mb-2">I <i>Dati Personali</i> trasmessi dagli <i>Utenti</i>, ossia da soggetti che navigano sul <i>Sito</i> ovvero che inviano richieste di informazioni, di contatto o di appuntamenti tramite la sezione “Contatti” del <i>Sito</i>, saranno trattati dal <i>Titolare del Trattamento</i> ai sensi dell’art. 6 del <i>Regolamento</i>, nel rispetto della <i>Normativa Applicabile</i> e per le seguenti finalità di trattamento:</p>
        <ul className="mb-2">
          <li>a) navigazione;</li>
          <li>b) risposta;</li>
          <li>c) obblighi di legge.</li>
        </ul>
        <p className="mb-2">Per “finalità di navigazione” (lett. a)) si intende la sola rilevazione dei <i>Dati</i> dell’<i>Utente</i> necessari a livello tecnico per la navigazione all’interno del <i>Sito</i>. I sistemi informatici e le procedure software preposte al funzionamento del <i>Sito</i>, infatti, acquisiscono, nel corso del loro normale esercizio, alcuni <i>Dati Personali</i> la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet. In questa categoria di <i>Dati</i> rientrano gli indirizzi IP o i nomi a dominio dei computer e dei terminali utilizzati dagli <i>Utenti</i>, gli indirizzi in notazione URI/URL (Uniform Resource Identifier/Locator) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all'ambiente informatico dell'<i>Utente</i>.</p>
        <p className="mb-2">Per “finalità di risposta” (lett. b)) si intende la rilevazione dei <i>Dati</i> dell’<i>Utente</i> necessaria a fornire una risposta alla richiesta di informazioni, di contatto o di appuntamenti pervenute tramite l’apposito modulo presente nella sezione “Contatti” del <i>Sito</i>.</p>
        <p className="mb-2">In relazione alle finalità di cui alle lett. a) e b), il trattamento dei <i>Dati</i> dell’<i>Utente</i> trova base giuridica nell’art. 6, comma 1, lett. a) e b) del Regolamento.</p>
        <p className="mb-2">Per finalità di “obblighi di legge” (lett. c)), si intende la rilevazione dei <i>Dati</i> dell’<i>Utente</i> necessaria a adempiere ad obblighi previsti dalla legge italiana, da un’Autorità, da un regolamento o dalla normativa europea.</p>
        <p className="mb-2">In relazione alla finalità di cui alla lett. c), il trattamento dei <i>Dati</i> dell’<i>Utente</i> trova base giuridica nell’art. 6, comma 1, lett. c) del Regolamento.</p>

        <h3 className="mt-4">3. Modalità e periodo di trattamento</h3>
        <p className="mb-2">Il <i>Titolare del Trattamento</i> tratterà i <i>Dati Personali</i> raccolti mediante strumenti manuali ed informatici, con logiche strettamente correlate alle finalità sopramenzionate, in ogni caso il <i>Titolare del Trattamento</i> garantirà la sicurezza e la riservatezza dei <i>Dati</i> stessi, che saranno conservati per i tempi strettamente necessari al perseguimento delle finalità per le quali sono state raccolti.</p>
        <p className="mb-2">L'<i>Utente</i> si assume la responsabilità dei <i>Dati Personali</i> di terzi pubblicati o condivisi mediante questo <i>Sito</i> e garantisce di avere il diritto di comunicarli e diffonderli, liberando il <i>Titolare del Trattamento</i> da ogni responsabilità verso terzi.</p>

        <h3 className="mt-4">4. Luogo di trattamento</h3>
        <p className="mb-2">I <i>Dati</i> sono sempre trattati presso le sedi operative del Dott. Francesco Latini, <i>Titolare del Trattamento</i>, e in ogni altro luogo in cui le parti coinvolte nel trattamento dei <i>Dati Personali</i> siano localizzate.</p>

        <h3 className="mt-4">5. Diritti dell’Utente</h3>
        <p className="mb-2">Gli <i>Utenti</i> possono esercitare tutti i diritti garantiti dalla <i>Normativa Applicabile</i> contattando il <i>Titolare del Trattamento</i> al seguente indirizzo e-mail: <i>francesco.latini.psy@gmail.com</i>.</p>
        <p className="mb-2">Ove si ritenga che il trattamento effettuato dal Dott. Francesco Latini violi il <i>Regolamento</i>, è possibile proporre reclamo alla competente Autorità di controllo (nello Stato membro in cui risiedono abitualmente, in quello in cui lavorano oppure in quello in cui si è verificata la presunta violazione). L’Autorità di controllo italiana è il Garante per la protezione dei dati personali, con sede in Piazza Venezia 11, CAP 00187 – Roma (http://www.garanteprivacy.it/), e-mail: garante@gpdp.it, fax: (+39) 06.69677.3785, centralino telefonico: (+39) 06.69677.1.</p>
        <p className="mb-2">I diritti dell’<i>Interessato</i> sono espressamente indicati dagli artt. 15, 16, 17, 18, 19, 20, 21, 22 del <i>Regolamento</i>.</p>
        <p className="mb-2">Il sito del Dott. Francesco Latini fa utilizzo di Cookie. Per avere maggiori informazioni riguardo ai Cookie utilizzati o per prendere visione dell’informativa dettagliata, l’<i>Utente</i> può consultare la Cookie Policy pubblicata nell’apposita sezione del <i>Sito</i>.</p>

      </article>
    </section>
  </Layout>;
};

export default PrivacyPolicyPage;
